import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Plugins } from '@capacitor/core';
const { Device } = Plugins;
const { Geolocation } = Plugins;

import { WebAPIService } from 'src/app/services/webapi/web-api.service';
import { WebAPIConstants, GlobalConstants } from 'src/app/services/globalConstants/global-constants.service';

import { IDeviceInfo } from 'src/app/interfaces/IDeviceInfo';
@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {

  //#region Properties

  public CurrentDeviceInfo: IDeviceInfo = this.getEmptyDeviceInfo();

  //#endregion Properties

  //#region Constructor

  constructor(private _platform: Platform,
              private _webAPISrv: WebAPIService) {

  }

  //#endregion Constructor

  //#region Get WebAPI / Device Info

  public getEmptyDeviceInfo(): IDeviceInfo {
    const item: IDeviceInfo = {
      AppVersion: '-',
      //
      WebAPIURL: '-',
      WebAPIVersion: '-',
      WebAPIType: '-',
      //
      DB: '-',
      DBVersion: '-',
      DBLogUpdate: '-',
      //
      WebAppVersion: '-',
      //
      DeviceUUID: '-',
      DeviceManufacturer: '-',
      DeviceModel: '-',
      DeviceOS: '-',
      DeviceOSVersion: '-',
      DevicePlatform: '-',
      DeviceIPAddressPublic: '-',
      DeviceScreenResolution: '-',
      //
      DeviceLat: null,
      DeviceLng: null,
      DeviceGeoLocation: '-',
      //
      BrowserName: '-',
      BrowserVersion: '-',
    };

    return item;
  }

  public async getDeviceInfo(): Promise<any> {

    console.log('DeviceInfoService >> getDeviceInfo');

    // recupera datos del servidor local desde el webAPI
    const urlWebAPI: string = WebAPIConstants.URLWebAPI + WebAPIConstants.URLSoporte + 'getAllServerInfo';

    const res = await this._webAPISrv.get(urlWebAPI, '').toPromise().then(async (webServerInfo: IDeviceInfo) => {

      // console.log('DeviceInfoService >> getDeviceInfo >> getAllServerInfo()');

      await Device.getInfo().then(async deviceData => { // recupera datos propios del dispositivo

        // console.log('DeviceInfoService >> getDeviceInfo >> Device.getInfo()');

        await Geolocation.getCurrentPosition().then(async (geoData) => { // recupera posicion geografica

          // console.log('DeviceInfoService >> getDeviceInfo >> Geolocation.getCurrentPosition()');
          this.CurrentDeviceInfo = {
            AppVersion: GlobalConstants.APP_VERSION,
            //
            WebAPIURL: WebAPIConstants.URLWebAPI,
            WebAPIVersion: webServerInfo.WebAPIVersion,
            WebAPIType: webServerInfo.WebAPIType,
            //
            DB: webServerInfo.DB,
            DBVersion: webServerInfo.DBVersion,
            DBLogUpdate: webServerInfo.DBLogUpdate,
            //
            WebAppVersion: webServerInfo.WebAppVersion,
            //
            DeviceUUID: deviceData.uuid,
            DeviceManufacturer: deviceData.manufacturer,
            DeviceModel: deviceData.model,
            DeviceOS: deviceData.operatingSystem,
            DeviceOSVersion: deviceData.osVersion,
            DevicePlatform: '',
            DeviceIPAddressPublic: webServerInfo.DeviceIPAddressPublic,
            DeviceScreenResolution: this._platform.width() + 'x' + this._platform.height(),
            //
            DeviceLat: geoData.coords.latitude,
            DeviceLng: geoData.coords.longitude,
            DeviceGeoLocation: geoData.coords.latitude + ', ' + geoData.coords.longitude,
            //
            BrowserName: webServerInfo.BrowserName,
            BrowserVersion: webServerInfo.BrowserVersion,
          };

          // da formato las plataformas desde _platform
          this.CurrentDeviceInfo.DevicePlatform += ' ('; // agrega parentesis
          this._platform.platforms().forEach((itemP)  => { // recorre para concatenar en un string
            this.CurrentDeviceInfo.DevicePlatform += itemP + ', ';
          });

          // remueve la ultima coma
          if (this._platform.platforms().length > 0) {
            this.CurrentDeviceInfo.DevicePlatform =
              this.CurrentDeviceInfo.DevicePlatform.substring(0, this.CurrentDeviceInfo.DevicePlatform.length - 2);
          }

          // cierra parentesis
          this.CurrentDeviceInfo.DevicePlatform += ')';

        }).catch(async () => { // hay error en la geolocalizacion

          // console.log('DeviceInfoService >> getDeviceInfo >> Geolocation.getCurrentPosition() >> Error');

          this.CurrentDeviceInfo = {
            AppVersion: GlobalConstants.APP_VERSION,
            //
            WebAPIURL: WebAPIConstants.URLWebAPI,
            WebAPIVersion: webServerInfo.WebAPIVersion,
            WebAPIType: webServerInfo.WebAPIType,
            //
            DB: webServerInfo.DB,
            DBVersion: webServerInfo.DBVersion,
            DBLogUpdate: webServerInfo.DBLogUpdate,
            //
            WebAppVersion: webServerInfo.WebAppVersion,
            //
            DeviceUUID: deviceData.uuid,
            DeviceManufacturer: deviceData.manufacturer,
            DeviceModel: deviceData.model,
            DeviceOS: deviceData.operatingSystem,
            DeviceOSVersion: deviceData.osVersion,
            DevicePlatform: '',
            DeviceIPAddressPublic: webServerInfo.DeviceIPAddressPublic,
            DeviceScreenResolution: this._platform.width() + 'x' + this._platform.height(),
            //
            DeviceLat: null,
            DeviceLng: null,
            DeviceGeoLocation: '',
            //
            BrowserName: webServerInfo.BrowserName,
            BrowserVersion: webServerInfo.BrowserVersion,
            //
          };

          // da formato las plataformas desde _platform
          this.CurrentDeviceInfo.DevicePlatform += ' ('; // agrega parentesis
          this._platform.platforms().forEach((itemP)  => { // recorre para concatenar en un string
            this.CurrentDeviceInfo.DevicePlatform += itemP + ', ';
          });

          // remueve la ultima coma
          if (this._platform.platforms().length > 0) {
            this.CurrentDeviceInfo.DevicePlatform =
              this.CurrentDeviceInfo.DevicePlatform.substring(0, this.CurrentDeviceInfo.DevicePlatform.length - 2);
          }

          // cierra parentesis
          this.CurrentDeviceInfo.DevicePlatform += ')';

        });
      });

    });

    // console.log('DeviceInfoService >> getDeviceInfo >> ' + JSON.stringify(this.CurrentDeviceInfo));

    return res;
  }

  //#endregion

}
