// tslint:disable: max-line-length
import { NgModule } from '@angular/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpClientModule } from '@angular/common/http';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '../environments/environment';

import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
import { IonCleaveDirectiveModule } from 'ion-cleave-directive';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';

import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';

import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
// import { IonicStorageModule } from '@ionic/storage-angular';
// import { Drivers } from '@ionic/storage';
// import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

// Pages

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    //
    Ionic4DatepickerModule,
    //
    IonCleaveDirectiveModule,
    NgxIonicImageViewerModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    CurrencyPipe,
    DecimalPipe,
    InAppBrowser,
    OneSignal,
    Camera,
    NativeStorage
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
