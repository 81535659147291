import { Injectable } from '@angular/core';
import { IGlobalConstants } from 'src/app/interfaces/IGlobalConstants';
import { IWebAPIConstants } from 'src/app/interfaces/IWebAPIConstants';

///////////////////////////////////////////////////////////////////
// const urlWebAPI: string = 'https://nutec-calidad.com.mx/WebAPI/'; // Web Produccion
const urlWebAPI: string = 'https://skia.tech/nutec5s/webapi/' // Web Produccion
// const urlWebAPI: string = 'http://192.168.0.137/Nutec5s/WebAPI2/'; // Web Desarrollo
// const urlWebAPI: string = 'http://localhost/Nutec5s/WebAPI2/'; // Web Desarrollo
///////////////////////////////////////////////////////////////////

export const GlobalConstants: IGlobalConstants = {
  APP_VERSION: 'v1.0.19.230803.2000',
  //
  DEVELOPMENT_ENABLED: true,
  DEVELOPMENT_USER: 'soporte',
  DEVELOPMENT_PWD: 'sk2021',
  //
  TOAST_DURATION_TIME: 1.5 * 1000, // 1.5 seg
  TOAST_DURATION_TIME_SLOW: 10 * 1000, // 10 seg
  TOAST_DURATION_TIME_FAST: 0.1 * 1000, // 0.1 seg
  //
  APP_ID: '0efc9843-7b3f-4858-9ff7-7cfa15702051',
  APP_GOOGLE_PROJECT_NUMBER: '867661569567'
};

///////////////////////////////////////////////////////////////////
// Configuración del WebAPI.
///////////////////////////////////////////////////////////////////
export const WebAPIConstants: IWebAPIConstants = {
  URLWebAPI: urlWebAPI,
  //
  URLSoporte: 'soporte/',
  URLError: 'error/',
  //
  URLAuditoria: 'auditoria/',
  //
  URLPlaneacion: 'planeacion/',
  //
  URLCliente: 'cliente/',
  URLUsuarios: 'usuarios/',
  URLUsuariosToken: 'Token/',
  URLUsuariosRegistrar: 'Account/Register/',
  //
  URLCatalogo: 'catalogo/',
  URLArchivo: 'archivo/',
  URLUploads: 'uploads/',
  //
  URLReportes: 'reportes/',
  //
  URLNotificacion: 'notificacion/',
  URLEmail: 'email/',
  //
  URLDashboard: 'dashboard/',
  //
  ResponseOk: 'OK'
};

@Injectable({
  providedIn: 'root'
})
export class GlobalConstantsService {

  constructor() { }

}
