// tslint:disable: max-line-length
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const allRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  // ---------------
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  // -------------
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  // ---------------
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  // ---------------
  {
    path: 'semanas',
    loadChildren: () => import('./pages/semanas/semanas.module').then(m => m.SemanasPageModule)
  },
  // --------------
  {
    path: 'auditoria',
    loadChildren: () => import('./pages/auditoria/auditoria.module').then(m => m.AuditoriaPageModule)
  },
  // --------------
  {
    path: 'auditoriaDetalle',
    loadChildren: () => import('./pages/auditoriaDetalle/auditoria-detalle.module').then(m => m.AuditoriaDetallePageModule)
  },
  // --------------
  {
    path: 'auditoriaDetalleEvidencia',
    loadChildren: () => import('./pages/auditoriaDetalleEvidencia/auditoria-detalle-evidencia.module').then(m => m.AuditoriaDetalleEvidenciaPageModule)
  },
  // --------------
  {
    path: 'auditoriaDetalleIncidencia',
    loadChildren: () => import('./pages/auditoriaDetalleIncidencia/auditoria-detalle-incidencia.module').then(m => m.AuditoriaDetalleIncidenciaPageModule)
  },
  // --------------
  {
    path: 'planeacion',
    loadChildren: () => import('./pages/planeacion/planeacion.module').then(m => m.PlaneacionPageModule)
  },
  // --------------
  {
    path: 'planeacionDetalle',
    loadChildren: () => import('./pages/planeacionDetalle/planeacion-detalle.module').then(m => m.PlaneacionDetallePageModule)
  },
  // --------------
  {
    path: 'incidente',
    loadChildren: () => import('./pages/incidente/incidente.module').then(m => m.IncidentePageModule)
  },
  // --------------
  {
    path: 'incidenteDetalle',
    loadChildren: () => import('./pages/incidenteDetalle/incidente-detalle.module').then(m => m.IncidenteDetallePageModule)
  },
  // --------------
  {
    path: 'layoutPlanta1',
    loadChildren: () => import('./pages/layoutPlanta1/layout-planta1.module').then(m => m.LayoutPlanta1PageModule)
  },
  // --------------
  {
    path: 'layoutPlanta2',
    loadChildren: () => import('./pages/layoutPlanta2/layout-planta2.module').then(m => m.LayoutPlanta2PageModule)
  },
  // --------------
  {
    path: 'layoutPlanta3',
    loadChildren: () => import('./pages/layoutPlanta3/layout-planta3.module').then(m => m.LayoutPlanta3PageModule)
  },
  // --------------
  {
    path: 'usuario',
    loadChildren: () => import('./pages/usuario/usuario.module').then(m => m.UsuarioPageModule)
  },
  // ---------------
  {
    path: 'usuarioDetalle',
    loadChildren: () => import('./pages/usuarioDetalle/usuario-detalle.module').then(m => m.UsuarioDetallePageModule)
  },
  // --------------
  {
    path: 'planta',
    loadChildren: () => import('./pages/planta/planta.module').then(m => m.PlantaPageModule)
  },
  // --------------
  {
    path: 'plantaDetalle',
    loadChildren: () => import('./pages/plantaDetalle/planta-detalle.module').then(m => m.PlantaDetallePageModule)
  },
  // --------------
  {
    path: 'area',
    loadChildren: () => import('./pages/area/area.module').then(m => m.AreaPageModule)
  },
  // --------------
  {
    path: 'areaDetalle',
    loadChildren: () => import('./pages/areaDetalle/area-detalle.module').then(m => m.AreaDetallePageModule)
  },
  // --------------
  {
    path: 'releaseNotes',
    loadChildren: () => import('./pages/releaseNotes/release-notes.module').then(m => m.ReleaseNotesPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(allRoutes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
