import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WebAPIConstants } from 'src/app/services/globalConstants/global-constants.service';

import { WebAPIService } from 'src/app/services/webapi/web-api.service';
import { DeviceInfoService } from 'src/app/services/deviceInfo/device-info.service';

import { IUsuario } from 'src/app/interfaces/IUsuario';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  //#region Properties

  public AllUsuarios: Array<IUsuario> = [];

  public CurrentUser: IUsuario = this.getNewUser();
  public EditedUser: IUsuario = this.getNewUser();

  public CurrentIdDispositivo: string = '-';

  public Ok: boolean = false;
  public Msg: string;

  //#endregion Properties

  //#region Constructor

  constructor(private _webAPISrv: WebAPIService,
              private _deviceInfoSrv: DeviceInfoService) {

  }

  //#endregion Constructor

  //#region Otros Metodos

  public getNewUser(): IUsuario {

    const newUsuario: IUsuario = {
      IdUsuario: 0,
      Usuario: '',
      UsuarioTxt: '',
      NumeroEmpleado: '',
      Nombre: '',
      Email: '',
      Telefono: '',
      Password: '',
      IdStatus: '',
      StatusTxt: '',
      UUID: '',
      FechaCreacion: '',
      FechaCreacionTxt: '',
      UsuarioCreacion: '',
      UsuarioCreacionTxt: '',
      IconVisible: false,
      IconName: '',
      IconNameIOs: '',
      IconNameMD: '',
      IconColor: '',
      Logged: false,
      Token: '',
      DesdeMain: false,
      Administrador: false,
      Usuarios: false,
      Configuracion: false,
      //
      Dashboard: false,
      AuditoriaConsultar: false,
      AuditoriaEditar: false,
      Cliente: false,
      //
      Msg: '',
      Ok: false,
      //
      allPlantas: [],
      allAreas: []
    };

    return newUsuario;
  }

  //#region Login

  public login(usuario: string, password: string, UUID: string): Observable<any> {

    console.log('UsuariosService >> login');

    this.CurrentUser = this.getNewUser();

    this.CurrentUser.Usuario = usuario;
    this.CurrentUser.Password = password;
    this.CurrentUser.UUID = UUID;

    const url: string = WebAPIConstants.URLWebAPI + WebAPIConstants.URLUsuarios + 'login/' + this.CurrentIdDispositivo;

    return this._webAPISrv.post(url, this.CurrentUser, '', false).pipe(map((newUser: IUsuario) => {
      this.CurrentUser = newUser;

      const existe: boolean = (this.CurrentUser.IdUsuario > 0);
      const activo: boolean = (this.CurrentUser.IdStatus == 'A');
      if (existe && activo) {

        // usuario ok
        this.CurrentUser.Logged = true;
      } else {
        // usuario not ok
        this.CurrentUser.Logged = false;
      }

      console.log('-------------------------------');
      console.log('IdUsuario: ' + this.CurrentUser.IdUsuario);
      console.log('Nombre: ' + this.CurrentUser.Nombre);
      console.log('IdStatus: ' + this.CurrentUser.IdStatus);
      console.log('----------- Token ----------');
      console.log('Token: ' + this.CurrentUser.Token);
      console.log('----------- Permisos ----------');
      console.log('Administrador: ' + this.CurrentUser.Administrador);
      console.log('Configuracion: ' + this.CurrentUser.Configuracion);
      console.log('Usuarios: ' + this.CurrentUser.Usuarios);
      console.log('Clientes: ' + this.CurrentUser.Cliente);
      console.log('-------------------------------');
    }));

  }

  public logout(): void {
    console.log('UsuariosService >> logout');

    // resetea usuario actual
    this.CurrentUser = this.getNewUser();
  }

  //#endregion

  public getAllUsuarios(idSucursal: string, txt: string) {

    console.log('UsuarioService >> getAllUsuarios');

    if (idSucursal == null) { idSucursal = '-'; }
    if (txt == null || txt == '') { txt = '-'; }

    const url: string = WebAPIConstants.URLWebAPI + WebAPIConstants.URLUsuarios +
      'getAllUsuarios/' + idSucursal + '/' + txt;
    const token: string = this.CurrentUser.Token;

    return this._webAPISrv.get(url, token).pipe(map((usuarios: Array<IUsuario>) => {
      this.AllUsuarios = usuarios;
    }));
  }

  public enviarNotificacionUsuario(idUsuario: number) {
    console.log('UsuarioService >> enviarNotificacionUsuario');

    const url: string = WebAPIConstants.URLWebAPI + WebAPIConstants.URLEmail + 'enviarNotificacionUsuario/' + idUsuario;
    const token: string = this.CurrentUser.Token;

    return this._webAPISrv.get(url, token).pipe(map((result: string) => {
      this.Msg = result;

      console.log('UsuarioService >> enviarNotificacionUsuario >> result: ' + result);
    }));
  }

  public getUsuario(idUsuario: number) {
    console.log('UsuarioService >> getUsuario');

    const url: string = WebAPIConstants.URLWebAPI + WebAPIConstants.URLUsuarios + 'getUsuario/' + idUsuario;
    const token: string = this.CurrentUser.Token;

    return this._webAPISrv.get(url, token).pipe(map((usuario: IUsuario) => {
      this.EditedUser = usuario;
    }));
  }
  //#endregion Otros Metodos

  //#region Guardar

  public guardar(usuario: IUsuario): Observable<any> {

    console.log('-------------------------------------------');
    console.log('UsuarioService >> guardar');

    const UUID: string = this._deviceInfoSrv.CurrentDeviceInfo.DeviceUUID;
    const url: string = WebAPIConstants.URLWebAPI + WebAPIConstants.URLUsuarios + 'guardar/'  + UUID;
    return this._webAPISrv.post(url, usuario, this.CurrentUser.Token, true).pipe(map((newUsuario: IUsuario) => {
      this.EditedUser = newUsuario;
      this.Msg = newUsuario.Msg;
      this.Ok = newUsuario.Ok;
      console.log('-------------------------------');
      console.log('IdUsuario: ' + newUsuario.IdUsuario);
      console.log('CurrentUser: ' + JSON.stringify(this.CurrentUser));
      console.log('Ok: ' + newUsuario.Ok);
      console.log('Msg: ' + newUsuario.Msg);
      console.log('-------------------------------');
    }));
  }

  public eliminar(usuario: IUsuario): Observable<any> {

    console.log('-------------------------------------------');
    console.log('UsuarioService >> eliminar');

    const UUID: string = this._deviceInfoSrv.CurrentDeviceInfo.DeviceUUID;
    const url: string = WebAPIConstants.URLWebAPI + WebAPIConstants.URLUsuarios + 'eliminar/'  + UUID;
    return this._webAPISrv.post(url, usuario, this.CurrentUser.Token, true).pipe(map((ok: boolean) => {
      this.EditedUser = this.getNewUser();
      this.Ok = ok;

      console.log('-------------------------------');
      console.log('IdUsuario: ' + usuario.IdUsuario);
      console.log('Ok: ' + this.Ok);
      console.log('-------------------------------');
    }));
  }

  //#endregion

}

