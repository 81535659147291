import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebAPIService {

  constructor(public _http: HttpClient) { }

  public get(url: string, token: string): Observable<any> {

    // si el url no tiene diagonal al final, se la agrega
    if (!url.endsWith('/')) {
      url += '/';
    }

    // agrega token en URL
    url += token;

    console.log('WebAPIService >> Get >> WebAPI Call >> ' + url);
    return this._http.get(url, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).pipe(map(
      res => {
      return res;
    },
    (err: HttpErrorResponse) => {
      console.log('WebAPIService >> Get >> Error: ' + err.error);
      console.log('WebAPIService >> Get >> Name: ' + err.name);
      console.log('WebAPIService >> Get >> Message: ' + err.message);
      console.log('WebAPIService >> Get >> Status: ' + err.status);
    }));
  }

  public post(url: string, item: any, token: string, showConsole: boolean): Observable<any> {

    // Hace JSON el item
    const body: string = JSON.stringify(item);

    // si el url no tiene diagonal al final, se la agrega
    if (!url.endsWith('/')) {
      url += '/';
    }

    // agrega token en URL
    url += token;

    console.log('WebAPIService >> Post >> WebAPI Call >> ' + url);

    if (showConsole) {
      console.log('WebAPIService >> Post >> Body >> ' + body);
    }

    return this._http.post(url, body, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).pipe(map(
      res => {
        return res;
      },
      (err: HttpErrorResponse) => {
        console.log('WebAPIService >> Post >> Error: ' + err.error);
        console.log('WebAPIService >> Post >> Name: ' + err.name);
        console.log('WebAPIService >> Post >> Message: ' + err.message);
        console.log('WebAPIService >> Post >> Status: ' + err.status);
      }
    ));
  }
}
