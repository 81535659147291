// tslint:disable: deprecation
import { Component, OnInit, HostListener } from '@angular/core';
import { Platform, NavController, MenuController, AlertController } from '@ionic/angular';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

import { UsuarioService } from './services/usuario/usuario.service';
import { GlobalConstants } from './services/globalConstants/global-constants.service';

import { IMenuItem } from './interfaces/IMenuItem';
import { OneSignal } from '@ionic-native/onesignal/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  //#region " Propiedades "

  public _appVersion: string = GlobalConstants.APP_VERSION;
  public _selectedIndex: number = 1; // MenuItem Seleccionado (Dashboard)

  public _selectedIndexSubItemParent: number = -1; // SubMenuItem Seleccionado
  public _selectedIndexSubItem: number = -1; // SubMenuItem Seleccionado

  //#endregion

  //#region " MenuItems "

  public _allItems: Array<IMenuItem> = [
    {
      Id: 'home',
      Texto: 'Home',
      Url: '',
      Icon: 'home-outline',
      IsFunction: true,
      Collapsed: true,
      SubMenuItems: []
    },
    {
      Id: 'dashboard',
      Texto: 'Dashboard',
      Url: '/dashboard',
      Icon: 'podium-outline',
      IsFunction: false,
      Collapsed: true,
      SubMenuItems: []
    },
    {
      Id: 'auditoria',
      Texto: 'Auditorías',
      Url: '/auditoria',
      Icon: '',
      Imagen: 'audit-outline_blue24x24.png',
      IsFunction: false,
      Collapsed: true,
      SubMenuItems: []
    },
    {
      Id: 'planeacion',
      Texto: 'Planeación',
      Url: '/planeacion',
      Icon: 'calendar-outline',
      IsFunction: false,
      Collapsed: true,
      SubMenuItems: []
    },
    {
      Id: 'incidente',
      Texto: 'Hallazgos',
      Url: '/incidente',
      Icon: 'warning-outline',
      IsFunction: false,
      Collapsed: true,
      SubMenuItems: []
    },
    {
      Id: 'layout',
      Texto: 'Layouts',
      Url: '',
      Icon: 'map-outline',
      IsFunction: false,
      Collapsed: true,
      SubMenuItems: [
        {
          Id: 'layoutPlanta1',
          Texto: 'Planta 1',
          Url: '/layoutPlanta1',
          Icon: '',
          Imagen: 'factory-outline_blue24x24.png',
          IsFunction: false,
          Collapsed: true,
          SubMenuItems: []
        },
        {
          Id: 'layoutPlanta2',
          Texto: 'Planta 2',
          Url: '/layoutPlanta2',
          Icon: '',
          Imagen: 'factory-outline_blue24x24.png',
          IsFunction: false,
          Collapsed: true,
          SubMenuItems: []
        },
        {
          Id: 'layoutPlanta3',
          Texto: 'Planta 3',
          Url: '/layoutPlanta3',
          Icon: '',
          Imagen: 'factory-outline_blue24x24.png',
          IsFunction: false,
          Collapsed: true,
          SubMenuItems: []
        }
      ]
    },
    {
      Id: 'config',
      Texto: 'Configuración',
      Url: '',
      Icon: 'settings-outline',
      IsFunction: false,
      Collapsed: true,
      SubMenuItems: [
        {
          Id: 'planta',
          Texto: 'Plantas',
          Url: '/planta',
          Icon: '',
          Imagen: 'factory-outline_blue24x24.png',
          IsFunction: false,
          Collapsed: true,
          SubMenuItems: []
        },
        {
          Id: 'area',
          Texto: 'Áreas',
          Url: '/area',
          Icon: '',
          Imagen: 'diagram-outline_blue24x24.png',
          IsFunction: false,
          Collapsed: true,
          SubMenuItems: []
        },
        {
          Id: 'usuario',
          Texto: 'Usuarios',
          Url: '/usuario',
          Icon: 'person-outline',
          IsFunction: false,
          Collapsed: true,
          SubMenuItems: []
        }
      ]
    },
    //
    {
      Id: 'soporte',
      Texto: 'Soporte',
      Url: '',
      Icon: 'bug-outline',
      IsFunction: false,
      Collapsed: true,
      SubMenuItems: [
        {
          Id: 'releaseNotes',
          Texto: 'Notas de la Versión',
          Url: '/releaseNotes',
          Icon: 'logo-ionic',
          IsFunction: false,
          Collapsed: true,
          SubMenuItems: []
        },
        {
          Id: 'test',
          Texto: 'Test',
          Url: '/test',
          Icon: 'bulb-outline',
          IsFunction: false,
          Collapsed: true,
          SubMenuItems: []
        }
      ]
    },
    {
      Id: 'logout',
      Texto: 'Salir',
      Url: '',
      Icon: 'lock-closed-outline',
      IsFunction: true,
      Collapsed: true,
      SubMenuItems: []
    }
  ];

  //#endregion

  //#region " Constructor "

    constructor(public _platform: Platform,
              public _splashScreen: SplashScreen,
              public _statusBar: StatusBar,
              public _screenOrientation: ScreenOrientation,
              public _navCtrl: NavController,
              public _menuCtrl: MenuController,
              public _usuarioSrv: UsuarioService,
              public _oneSignal: OneSignal,
              public _alertCtrl: AlertController,
              public _storage: NativeStorage) {

    this.initializeApp();

  }

  //#endregion

  // Evita que el backspace haga que el browser vaya a la pagina anterior
  @HostListener('document:keydown ', ['$event'])
  public handleKeyup(event: KeyboardEvent) {
    if (event.keyCode === 8 &&
      document.activeElement != null &&
      ['TEXTAREA', 'INPUT'].indexOf(document.activeElement.nodeName) == -1) {
      event.preventDefault();
    }
  }

  //#region " Metodos NG "

  public initializeApp() {
    this._platform.ready().then(() => {

      if (this._platform.is('mobile')) {
        this._screenOrientation.lock(this._screenOrientation.ORIENTATIONS.PORTRAIT);
        this._statusBar.styleDefault();
        this._splashScreen.hide();
      }

      this._platform.platforms().forEach(itemPlatform => {
        console.log('Platform >> ' + itemPlatform)
      });

      if (this._platform.is('cordova')) {
         this.setupOneSignal();
      }

    });
  }

  private setupOneSignal() {
    console.log('APP Component >> setupOneSignal');

    this._oneSignal.startInit(GlobalConstants.APP_ID, GlobalConstants.APP_GOOGLE_PROJECT_NUMBER);

    this._oneSignal.inFocusDisplaying(this._oneSignal.OSInFocusDisplayOption.None);

    this._oneSignal.getIds().then(data => {
      console.log('APP Component >> setupOneSignal >> OneSignal UserId >> ' +   data.userId);
      this._usuarioSrv.CurrentIdDispositivo = data.userId;
    });

    this._oneSignal.handleNotificationReceived().subscribe(data => {
      const msg = data.payload.body;
      const title = data.payload.title;
      const additionalData = data.payload.additionalData;
      this.showAlert(title, msg, additionalData.task);
    });

    this._oneSignal.handleNotificationOpened().subscribe(data => {
      const additionalData = data.notification.payload.additionalData;
      this.showAlert('Notification opened', 'You alreade read this before', additionalData.task);
    });

    this._oneSignal.endInit();
  }

  private async showAlert(title, msg, task) {
    console.log('APP Component >> showAlert >> task: ' + JSON.stringify(task));
    const alert = await this._alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'Action: $(task)',
          handler: () => {
          }
        }
      ]
    })
    alert.present();
  }

  public ngOnInit() {
    const path = window.location.pathname.split('pages/')[1];
    if (path !== undefined) {
      this._selectedIndex = this._allItems.findIndex(page => page.Texto.toLowerCase() === path.toLowerCase());
    }
  }

  //#endregion

  //#region " Eventos Botones "

  public btnMenuItemClick(item: IMenuItem) {

    switch (item.Id) {

      case 'home':
        break;

      case 'logout':

        this._selectedIndexSubItemParent = -1; // SubMenuItem Seleccionado
        this._selectedIndexSubItem = -1; // SubMenuItem Seleccionado
        this._selectedIndex = -1;

        this._allItems.forEach(itemMenu => {
          itemMenu.Collapsed = true;
          itemMenu.SubMenuItems.forEach(itemSubMenu => {
            itemSubMenu.Collapsed = true;
          });
        });

        this._menuCtrl.enable(false); // Oculta menu
        this._usuarioSrv.logout(); // cierra sesi�n
        this._selectedIndex = 0; // Item seleccionado default (home)

        // limpia el storage donde se guarda el usuario
        if (this._platform.is('mobile')) {
          this._storage.clear();
        }

        this._navCtrl.navigateForward('/login'); // redirecciona al login.

        break;

      default:
        break;
    }

  }

  //#endregion

  //#region " Permisos "

  public tienePermiso(menuItem: IMenuItem): boolean {
    switch (menuItem.Id) {
      case 'home':
        return this._usuarioSrv.CurrentUser.DesdeMain;

      case 'dashboard':
        return this._usuarioSrv.CurrentUser.Administrador ||
        this._usuarioSrv.CurrentUser.Dashboard;

      case 'auditoria':
        return this._usuarioSrv.CurrentUser.AuditoriaConsultar || this._usuarioSrv.CurrentUser.AuditoriaEditar ||
          this._usuarioSrv.CurrentUser.Administrador;

      case 'planeacion':
        return this._usuarioSrv.CurrentUser.Administrador || this._usuarioSrv.CurrentUser.PlaneacionConsultar ||
          this._usuarioSrv.CurrentUser.PlaneacionEditar;

      case 'incidente':
        return this._usuarioSrv.CurrentUser.Administrador || this._usuarioSrv.CurrentUser.IncidenteConsultar ||
          this._usuarioSrv.CurrentUser.IncidenteEditar || this._usuarioSrv.CurrentUser.IncidenteCerrar;

      case 'layout':
        return this._usuarioSrv.CurrentUser.Administrador || this._usuarioSrv.CurrentUser.LayoutConsultar;

      case 'layoutPlanta1':
        return this._usuarioSrv.CurrentUser.Administrador || this._usuarioSrv.CurrentUser.LayoutConsultar;

      case 'layoutPlanta2':
        return this._usuarioSrv.CurrentUser.Administrador || this._usuarioSrv.CurrentUser.LayoutConsultar;

      case 'layoutPlanta3':
        return this._usuarioSrv.CurrentUser.Administrador || this._usuarioSrv.CurrentUser.LayoutConsultar;

      case 'config':
        return this._usuarioSrv.CurrentUser.Administrador ||
               this._usuarioSrv.CurrentUser.Usuarios;

      case 'planta':
        return this._usuarioSrv.CurrentUser.Administrador || this._usuarioSrv.CurrentUser.Configuracion;

      case 'area':
        return this._usuarioSrv.CurrentUser.Administrador || this._usuarioSrv.CurrentUser.Configuracion;

      case 'usuario':
        return  this._usuarioSrv.CurrentUser.Administrador || this._usuarioSrv.CurrentUser.Usuarios;

      case 'soporte':
        return this._usuarioSrv.CurrentUser.Administrador;

      case 'releaseNotes':
        return this._usuarioSrv.CurrentUser.Administrador;

      case 'logout':
        return true;

      default:
        return false;
    }
  }

  public showMenuAsPanel(): boolean {
    return false;
    // if (this._platform.is('mobile')) {
    //   return false;
    // } else {
    //   return (this._usuarioSrv.CurrentUser.PoSShowMenuAsPanel);
    // }
  }

  //#endregion

  //#region " Otros Eventos "

  //#endregion

}
